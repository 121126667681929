import { Route, Routes } from 'react-router-dom'
import {useLocation } from "react-router-dom";
import Form from "./Form/Form";
import Send from './Form/send';

function App() {
    const tg = window.Telegram.WebApp;
    
    var backButton = window.Telegram.WebApp.BackButton;
    tg.setBottomBarColor('#d77c1b')
    if (useLocation().pathname !== '/') {

        backButton.show();
      
      } else {
      
        backButton.hide(); 
      
      }
      backButton.onClick(() => {
           window.history.go(-1)
          });

    window.Telegram.WebApp.expand();
    window.Telegram.WebApp.disableVerticalSwipes()
    tg.setHeaderColor("#d77c1b");

    return (
        <div >
            <Routes>
                <Route path="/" element={<Form />} />
                <Route path="/send" element={<Send />} />
                </Routes>
            
        </div>
    );
}

export default App