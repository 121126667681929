import React, { useState, useEffect } from 'react';
import { Input, Label, TextField, Button } from 'react-aria-components';
import { localUrl } from '../localSettings.js'
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import './Form.css'

const APIURL = localUrl.APIURL;

const Form = () => {


    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        bonusNumber: NaN,
        receipt: NaN,
        receiptMarked: NaN
    });
    // const [formData, setFormData] = useState({
        
    // });

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const { firstName, lastName, phone, bonusNumber, receipt } = formData;
        setIsFormValid(firstName.length > 0 && lastName.length > 0 && (phone.length > 10) && receipt && bonusNumber);
    }, [formData]);

    const handleChange = (v, id) => {
        console.log(v, id)
        setFormData((prevData) => ({
            ...prevData,
            [id]: v
        }));
    };

    

    const handleSubmit = (e) => {
        console.log('trying to submit ', formData);
        fetch(APIURL + '/send-winter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, initData: window.Telegram.WebApp.initData })
        })
        //    navigate('/')
        navigate('/send', {
            replace: true,
            state: { sent: true }
        });
    };
    console.log('FormData', formData)
    return (

        <div className='main'>
              <div className='header'>
            Участвовать
            </div>
            <form onSubmit={handleSubmit} >
               
                <div className='container'>
                <TextField className='field'
                                    aria-label='firstName'
                    onChange={(v) => handleChange(v, 'firstName')}
                    onInput={(v) => handleChange(v, 'firstName')}

                >
                    <Label className='label'>Ваше имя</Label>
                    <Input className='input' />
                </TextField>
                </div >
                <div className='container'>
                <TextField className='field'
                    // aria-label='lastName'
                    onChange={(v) => handleChange(v, 'lastName')}
                    onInput={(v) => handleChange(v, 'lastName')}
                >
                    <Label className='label'>Ваша фамилия</Label>
                    <Input className='input' />
                </TextField>
                </div >
                <div className='container'>
                <PhoneInput
                    // aria-label='phone'
                    inputClass='input1'
                    buttonStyle={{display: 'none'}}
                    dropdownStyle={{display: 'none'}}
                    searchStyle={{display: 'none'}}
                    country={'ru'}
                    specialLabel={'Номер телефона для связи'}
                    containerClass='field'
                    onChange={(v) => handleChange(v, 'phone')}
                    onInput={(v) => handleChange(v, 'phone')}
                >
                </PhoneInput>
                </div>
                <div className='container'>
                <TextField
                    className='field'
                    // aria-label='bonusNumber'
                    onChange={(v) => handleChange(v, 'bonusNumber')}
                    onInput={(v) => handleChange(v, 'bonusNumber')}
                    value={formData.bonusNumber}
                    name="receibonusNumberptNumber">
                    <Label className='label'>Номер бонусной карты</Label>
                    <Input className='input'
                    
                        name="bonusNumber"
			            type='number' inputmode="numeric">
                    </Input>
                </TextField>
                </div>
                <div className='container'>
                <TextField
                    id='reciept'
                    onChange={(v) => handleChange(v, 'receipt')}
                    onInput={(v) => handleChange(v, 'receipt')}
                    className='field'
                    // aria-label='receipt'
                    value={formData.receipt}
                    name="receiptNumber">
                    <Label className='label'>Номер чека (ОФД)</Label>
                    <Input className='input'
                        name="receiptNumber"
			            type='number' inputmode="numeric">
                    </Input>
                </TextField>
                </div>
                <div className='container'>
                <TextField
                    // className='input'
                    className='field'
                    // aria-label='receiptMarked'
                    onChange={(v) => handleChange(v, 'receiptMarked')}
                    onInput={(v) => handleChange(v, 'receiptMarked')}
                    value={formData.receiptMarked}
                    name="receiptNumberMarked">
                    <Label className='label'>
                        Номер чека (ОФД) с маркированным товаром (вода и газированные напитки, молочная продукция)
                    </Label>
                    
                    <Input className='input'
                        name="receiptNumberMark"
			            type='number' inputmode="numeric">
                    </Input>
                </TextField>
                </div>
                <div className='container'>
                <Button
                    onPress={handleSubmit}
                    className="submit"
                isDisabled={!isFormValid}
                >Принять участие</Button>
                   </div>

            </form>
        </div>
    );
};

export default Form;
